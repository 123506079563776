<template>
  <footer class="osk-footer">
    <FooterMenuTop />
    <FooterSeo />
    <FooterMenuBottom />
    <FooterBottom />
  </footer>
</template>

<script lang="ts" setup>

</script>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";

.osk-footer {
  @include font-body-all;
  background-color: $black;
  color: $white;
}
</style>
