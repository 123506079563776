<template>
  <div class="osk-footer-menu-top">
    <div class="osk-wrapper">
      <UikitCollapse
        class="osk-footer-menu-top__columns"
        :disable="desktopScreen"
      >
        <UikitCollapseItem
          v-for="(column, index) in footerMenu"
          :key="index"
          :title="$t(column.title)"
          class="osk-footer-menu-top__column"
        >
          <div
            v-for="(item, idx) in column.items"
            :key="idx"
            class="osk-footer-menu-top__item"
          >
            <a
              v-if="item.href"
              :href="item.hrefLocalized ? $t(item.href) : item.href"
              :target="item.external ? '_blank' : undefined"
              :rel="item.external ? 'nofollow noopener' : undefined"
              class="osk-footer-menu-top__link"
            >
              {{ item.title ? $t(item.title) : null }}
            </a>

            <span
              v-else
              class="osk-footer-menu-top__link osk-footer-menu-top__link_span"
            >{{ item.title ? $t(item.title) : null }}
              <a
                v-if="item.link"
                :href="$t(item.link.href)"
                v-html="$t(item.link.text)"
              />

              <span
                v-else-if="item.text"
                v-html="$t(item.text.text)"
              />

            </span>
          </div>
        </UikitCollapseItem>
      </UikitCollapse>

      <div class="osk-footer-menu-top__subscribe">
        <div class="osk-footer-menu-top__title">
          {{ $t('components.footer.footerMenuTop.subscribe') }}
        </div>

        <div class="footer-menu__column__content">
          <FooterSubscribeForm />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import menu from '~/data/footer';

const { isMounted, isDesktop } = useUiBreakpoints();
const { isInternational } = useInternational();

const footerMenu = computed(() => {
  if (isInternational) {
    return menu.map((menuColumn) => ({
      ...menuColumn,
      items: menuColumn.items.filter((menuColumnItem) => menuColumnItem.title && ![ 'data.footer.hotline', 'data.footer.findUs', 'data.footer.blog' ].includes(menuColumnItem.title)),
    }));
  }
  return menu;
});

const desktopScreen = computed(() => (!isMounted.value || isDesktop.value));
</script>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";

.osk-footer-menu-top {
  padding-top: $indent-small;
  display: flex;
  flex-direction: column;

  @include media-query(lg-and-up) {
    flex-direction: row;
    padding-top: $indent-mlarge;
    padding-bottom: $indent-large;
    border-bottom: 1px solid $grey;
  }

  .osk-wrapper {
    @include media-query(lg-and-up) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__columns {
    @include media-query(lg-and-up) {
      display: flex;
      width: 100%;
      margin-right: 32px;
    }
  }

  &__column {
    @include media-query(lg-and-up) {
      width: 25%;
    }
  }

  &__title,
  :deep(.osk-collapse-item__title) {
    @include font-style($font-size-subheading, $line-height-subheading-2, $font-weight-medium);
    padding: 14px 0;

    @include media-query(lg-and-up) {
      cursor: default;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      padding-top: 0;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include media-query(lg-and-up) {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }

  &__link {
    @include font-style($font-size-body, $line-height-subheading-2, $font-weight-medium);
    color: $grey-2;
    padding: 14px 0;
    display: inline-block;

    @include media-query(lg-and-up) {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      padding: 0;
      color: $white;

      &:hover:not(.osk-footer-menu-top__link_span) {
        font-weight: 600;
      }
    }
  }

  &__subscribe {
    @include media-query(lg-and-up) {
      width: 243px;
      width: 20%;
      flex-shrink: 0;
    }
  }
}
</style>
