import { ANCHORS } from '~/components/info/anchors';
import type { TFooterMenu } from '~/types/footer';

const footerMenu: TFooterMenu = [
  {
    title: 'data.footer.oskelly',
    items: [
      {
        title: 'data.footer.about',
        href: '/info/about',
      },
      {
        title: 'data.footer.guarantees',
        href: '/info/guarantees',
      },
      {
        title: 'data.footer.press',
        href: '/info/press',
      },
      {
        title: 'data.footer.blog',
        href: 'https://oskelly.ru/blog/',
      },
      {
        title: 'data.footer.etiquette',
        href: 'data.footer.etiquette.href',
        hrefLocalized: true,
      },
    ],
  },
  {
    title: 'data.footer.toCustomers',
    items: [
      {
        title: 'data.footer.howToBuy',
        href: `/info/buyer#${ANCHORS.HOW_TO_BUY}`,
      },
      {
        title: 'data.footer.guaranteeOfAuthenticity',
        href: `/info/buyer#${ANCHORS.GUARANTEE}`,
      },
      {
        title: 'data.footer.safeDeal',
        href: `/info/buyer#${ANCHORS.SAFE_DEAL}`,
      },
      {
        title: 'data.footer.deliveryAndReturn',
        href: `/info/buyer#${ANCHORS.DELIVERY_AND_REFUND}`,
      },
      {
        title: 'data.footer.auction',
        href: `/info/buyer#${ANCHORS.AUCTION}`,
      },
      {
        title: 'data.footer.tips',
        href: `/info/buyer#${ANCHORS.TIP}`,
      },
    ],
  },
  {
    title: 'data.footer.toSeller',
    items: [
      {
        title: 'data.footer.howToSell',
        href: `/info/seller#${ANCHORS.HOW_TO_SELL}`,
      },
      {
        title: 'data.footer.expertise',
        href: `/info/seller#${ANCHORS.OSKELLY_EXPERTISE}`,
      },
      {
        title: 'data.footer.safeDeal',
        href: `/info/seller#${ANCHORS.SAFE_DEAL}`,
      },
      {
        title: 'data.footer.commission',
        href: `/info/seller#${ANCHORS.COMMISSION}`,
      },
      {
        title: 'data.footer.becomeBoutique',
        href: `/info/seller#${ANCHORS.BECOME_BOUTIQUE}`,
      },
      {
        title: 'data.footer.tips',
        href: `/info/seller#${ANCHORS.TIP}`,
      },
    ],
  },
  {
    title: 'data.footer.helpAndSupport',
    items: [
      {
        title: 'data.footer.contacts',
        href: '/info/kontakty',
      },
      {
        title: 'data.footer.faq',
        href: 'components.info.offerYourPrice.openHelp.href',
        hrefLocalized: true,
      },
      {
        title: 'data.footer.hotline',
        link: {
          href: 'data.footer.hotlineHref',
          text: 'data.footer.hotlineText',
        },
      },
      {
        title: 'data.footer.telephone',
        link: {
          href: 'data.footer.telephoneHref',
          text: 'data.footer.telephoneText',
        },
      },
      {
        text: {
          text: 'data.footer.address',
        },
      },
      {
        title: 'data.footer.findUs',
        href: 'https://yandex.ru/maps/org/beegz/84060050205',
        external: true,
      },
    ],
  },
];

export default footerMenu;
