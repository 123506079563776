<template>
  <UikitDialog
    :model-value="modelValue"
    full-height
    close-others-on-mobile
    position="right"
    content-class="osk-error-dialog"
    @update:model-value="closeDialog($event)"
  >
    <div
      v-if="errorData?.title"
      class="osk-error-dialog__title"
    >
      {{ errorData.title }}
    </div>

    <div
      v-if="errorData?.subtitle"
      class="osk-error-dialog__subtitle"
    >
      {{ errorData.subtitle }}
    </div>

    <div class="osk-error-dialog__buttons">
      <UikitButton
        outline
        size="s"
        class="osk-error-dialog__button"
        @click="handleMain"
      >
        {{ $t('errorDialog.main') }}
      </UikitButton>

      <UikitButton
        outline
        size="s"
        class="osk-error-dialog__button"
        @click="handleWishlist"
      >
        {{ $t('errorDialog.wishlist') }}
      </UikitButton>
    </div>
  </UikitDialog>
</template>

<script setup lang="ts">
const props = defineProps<{
  modelValue: boolean
}>();

const emits = defineEmits<{
  (e: 'update:modelValue', value: boolean): void,
}>();

const { errorData } = useErrorDialog();
const { account } = useAccountStore();

const profileLink = computed(() => `/profile/${account.value.id}/wishlist`);

function closeDialog(value: boolean) {
  errorData.value = null;
  emits('update:modelValue', value);
}

async function handleMain() {
  await navigateTo({ path: '/' });

  await nextTick();

  closeDialog(false);
}

async function handleWishlist() {
  closeDialog(false);

  await nextTick();

  await navigateTo(profileLink.value);
}
</script>

<style lang="scss">
@import "~/assets/scss/settings/index";

.osk-error-dialog {
  @include font-body-all;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include media-query(lg-and-up) {
    padding: 0 52px !important;
    width: 504px !important;
  }

  .osk-dialog__close {
    @include media-query(lg-and-down) {
      top: 16px;
      right: 16px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    margin-bottom: 12px;
  }

  &__subtitle {
    margin-bottom: 32px;
  }

  &__buttons {
    display: flex;
  }

  &__button {
    &:first-child {
      margin-right: 12px;
    }
  }
}
</style>
