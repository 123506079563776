<template>
  <UikitForm
    class="osk-footer-subscribe-form"
    @submit-validate-success="submitSuccess"
  >
    <template v-if="!data.thanks">
      <div class="osk-footer-subscribe-form__radios">
        <UikitFormRadio
          v-model="data.sex"
          name="sex"
          value="FEMALE"
          :label="$t('components.footer.footerSubscribeForm.female')"
          color="white"
        />

        <UikitFormRadio
          v-model="data.sex"
          name="sex"
          value="MALE"
          :label="$t('components.footer.footerSubscribeForm.male')"
          color="white"
        />
      </div>

      <div class="osk-footer-subscribe-form__buttons">
        <UikitFormInput
          v-model="data.email"
          class="osk-footer-subscribe-form__input"
          name="email"
          placeholder="E-mail"
          rules="required|email"
          autocomplete="off"
        />

        <UikitButton
          icon="ArrowRight"
          color="white"
          :loading="loading"
        />
      </div>

      <div
        class="osk-footer-subscribe-form__info"
      >
        {{ $t('components.footer.footerSubscribeForm.info') }}
      </div>
    </template>

    <div
      v-else
      class="subscribe__thanks"
    >
      {{ $t('components.footer.footerSubscribeForm.thanks') }}
    </div>
  </UikitForm>
</template>

<script lang="ts" setup>
import { useAccountService } from '~/providers/account';
import { PrimaryPageType } from '~/restAPI/data-contracts';

type Sex = PrimaryPageType.MALE | PrimaryPageType.FEMALE;

const { isAuth, account } = useAccountStore();

function getDefaultSex() {
  if (!isAuth.value) return PrimaryPageType.FEMALE;
  if (account.value.sex === PrimaryPageType.MALE) return PrimaryPageType.MALE;
  if (account.value.sex === PrimaryPageType.FEMALE) return PrimaryPageType.FEMALE;
  return PrimaryPageType.FEMALE;
}

const data = ref<{
  sex: Sex,
  email: string,
  thanks: boolean,
}>({
  sex: getDefaultSex(),
  email: '',
  thanks: false,
});
const loading = ref(false);

const { fetchSubscribe } = useAccountService();

async function submitSuccess() {
  loading.value = true;

  await useAsyncData(() => fetchSubscribe({
    emailAddress: data.value.email,
    sex: data.value.sex,
  }).then(() => {
    data.value.thanks = true;
    setTimeout(() => {
      data.value.thanks = false;
      data.value.email = '';
    }, 2500);
  }));

  loading.value = false;
}
</script>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";

.osk-footer-subscribe-form {
  padding-top: $indent-small;
  padding-bottom: $indent-large;
  border-bottom: 0.5px solid $white;

  @include media-query(lg-and-up) {
    border-bottom: none;
  }

  &__radios {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: $indent-mlarge;

    @include media-query(lg-and-up) {
      margin-top: -4px;
    }

    :deep(.osk-field) {
      margin-top: 0;

      @include media-query(lg-and-up) {
        margin-top: $indent-xsmall;
      }

      &:first-child {
        margin-right: $indent-large;

        @include media-query(lg-and-up) {
          margin-right: $indent-mmedium;
        }
      }
    }

    :deep(.osk-radio__label) {
      @include font-style($font-size-body, $line-height-body, $font-weight-medium);
      margin-left: $indent-small;

      @include media-query(lg-and-up) {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
      }
    }

    :deep(.osk-radio__icon) {
      width: 14px;
      height: 14px;
    }
  }

  &__buttons {
    display: flex;
    margin-bottom: $indent-compact;

    :deep(.osk-button) {
      margin-top: 0;
      flex-shrink: 0;
      margin-left: $indent-small;
    }
  }

  &__input {
    width: 100%;

    &:deep(.osk-input__input) {
      background: transparent;

      &::placeholder {
        color: $white;
      }

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:autofill,
      &:autofill:hover,
      &:autofill:focus {
        -webkit-text-fill-color: #fff;
        -webkit-box-shadow: 0 0 0px 1000px #000 inset;
      }
    }

    &:deep(.osk-field__inner) {
      border-color: $white;
      height: 44px;
    }
  }

  &__info {
    @include font-style($font-size-caption, $line-height-caption, $font-weight-medium);
    color: $grey-3;
    max-width: 293px;
    opacity: 0.7;

    @include media-query(lg-and-up) {
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #FFFFFF;
      opacity: 1;
    }
  }
}
</style>
