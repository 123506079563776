<template>
  <div class="osk-footer-menu-bottom">
    <div class="osk-wrapper">
      <div class="osk-footer-menu-bottom__main">
        <div class="osk-footer-menu-bottom__socials">
          <template v-if="!isInternational">
            <a
              class="osk-footer-menu-bottom__socials-link"
              href="https://vk.com/oskellyco"
              target="_blank"
            >
              <UikitIcon
                name="VkIcon"
                size="xl"
                color="white"
              />
            </a>

            <a
              class="osk-footer-menu-bottom__socials-link"
              href="https://www.youtube.com/channel/UCp2_cHmFYSlS0bITJs9fhfQ?sub_confirmation=1"
              target="_blank"
            >
              <UikitIcon
                name="Youtube"
                size="xl"
                color="white"
              />
            </a>

            <a
              class="osk-footer-menu-bottom__socials-link"
              href="https://t.me/oskelly"
              target="_blank"
            >
              <UikitIcon
                name="Telegram"
                size="xl"
                color="white"
              />
            </a>
          </template>
          <template v-else>
            <a
              class="osk-footer-menu-bottom__socials-link"
              href="https://www.facebook.com/profile.php?id=100088848285796&mibextid=LQQJ4d"
              target="_blank"
            >
              <UikitIcon
                name="Facebook"
                size="l"
                color="white"
              />
            </a>

            <a
              class="osk-footer-menu-bottom__socials-link"
              href="https://instagram.com/oskelly.co?igshid=MWI4MTIyMDE="
              target="_blank"
            >
              <UikitIcon
                name="Instagram"
                size="l"
                color="white"
              />
            </a>

            <a
              class="osk-footer-menu-bottom__socials-link"
              href="https://www.linkedin.com/company/oskelly"
              target="_blank"
            >
              <UikitIcon
                name="Linkedin"
                size="l"
                color="white"
              />
            </a>
          </template>
        </div>
        <div
          class="osk-footer-menu-bottom__apps"
        >
          <a
            v-if="!isInternational"
            :href="apps.playMarket"
            target="_blank"
          >
            <img
              v-if="!isInternational"
              class="osk-footer-menu-bottom__apps-google-play"
              src="~assets/img/google-play-icon.svg"
              alt=""
            >
            <img
              v-else
              class="osk-footer-menu-bottom__apps-google-play"
              src="~assets/img/google-play-icon-int.svg"
              alt=""
            >
          </a>
          <a
            :href="$t('appStoreLink')"
            target="_blank"
          >
            <img
              v-if="!isInternational"
              class="osk-footer-menu-bottom__apps-apple"
              src="~assets/img/apple-store-icon.svg"
              alt=""
            >
            <img
              v-else
              class="osk-footer-menu-bottom__apps-apple"
              src="~assets/img/apple-store-icon-int.svg"
              alt=""
            >
          </a>
        </div>

        <div class="osk-footer-menu-bottom__copyright">
          {{ $t('components.footer.footerMenuBottom.copyright') }}
        </div>
      </div>

      <div class="osk-footer-menu-bottom__ownership-software osk-mt-mmd">
        {{ $t('components.footer.footerMenuBottom.ownershipSoftware') }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const { apps } = useCompanyInfo();
const { isInternational } = useInternational();
</script>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";

.osk-footer-menu-bottom {
  padding-bottom: $indent-mlarge;

  @include media-query(lg-and-up) {
    padding-bottom: 0;
  }

  &__main {
    @include media-query(lg-and-up) {
      display: grid;
      grid-template-columns: 1fr auto 1fr;
    }
  }

  &__socials {
    padding: $indent-large 0;
    border-bottom: 0.5px solid $white;
    justify-self: center;

    @include media-query(lg-and-up) {
      border: 0;
      order: 2;
    }
  }

  &__socials-link {
    display: inline-block;

    &:not(:last-child) {
      margin-right: $indent-medium;
    }
  }

  &__apps {
    padding: $indent-large 0;
    display: flex;
    justify-self: flex-end;

    @include media-query(lg-and-up) {
      order: 3;
    }
  }

  &__apps-google-play {
    height: 37px;
    margin-right: $indent-medium;

    @include media-query(lg-and-up) {
      height: 30px;
      width: auto;
    }
  }

  &__apps-apple {
    height: 37px;

    @include media-query(lg-and-up) {
      height: 30px;
      width: auto;
    }
  }

  &__copyright {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;

    @include media-query(lg-and-up) {
      order: 1;
    }
  }

  &__ownership-software {
    max-width: 504px;
    color: $grey-3;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;

    @include media-query(lg-and-up) {
      margin-top: 0;
      margin-bottom: $indent-large;
    }
  }
}
</style>
